import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import ENDPOINTS from '../utils/apiConfig';
import { useTheme } from '@mui/material/styles';
import Icon from '../components/Icon'; 

const UploadFilesField = ({ courseId, onFileUploadSuccess }) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const timer = useRef();
  const theme = useTheme();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: acceptedFiles => {
      const mappedFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }));
      setFiles(mappedFiles);
      triggerUploads(mappedFiles);
    },
    noClick: true,
  });

  const boxStyle = {
    border: isDragActive ? '2px dashed #4caf50' : '1px dashed grey',
    p: 2,
    textAlign: 'center',
    borderRadius: 3,
    width: 500,
    position: 'relative'
  };


  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const parseError = (errorResponse) => {
    if (errorResponse.file_error) {
      return "Du kannst diesen Dateityp nicht hochladen. Bitte wähle ein PDF aus.";
    } else if (errorResponse.file_processing_error) {
      return "Diese Datei hast du bereits hochgeladen.";
    }
    return "Ein unbekannter Fehler ist aufgetreten. Bitte versuche es erneut.";
  };

  const uploadFile = async (file) => {
    setUploading(true);
    setUploadSuccess(false);
    setUploadError('');

    const formData = new FormData();
    formData.append('file', file);
    formData.append('file_name', file.name);
    formData.append('course', courseId);

    try {
        const response = await fetch(ENDPOINTS.UPLOAD, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
            },
            body: formData,
        });

        const result = await response.json();
        if (!response.ok) {
            throw new Error(parseError(result));
        }

        // Assuming the response includes the file ID
        setUploadSuccess(true);
        timer.current = setTimeout(() => {
            setUploadSuccess(false);
        }, 4000);

        // Call the callback function with the file ID
        onFileUploadSuccess(result.id); // Pass the file ID to the CoursePage
    } catch (error) {
        console.error('Upload error:', error);
        setUploadError(error.message);
    }
    setUploading(false);
};


  const triggerUploads = async (files) => {
    setUploading(true);
    for (const file of files) {
      await uploadFile(file);
    }
    setUploading(false);
  };

  return (
    <Box {...getRootProps()} sx={boxStyle}>
      <input {...getInputProps()} />
      <Typography variant="body1" mb={2}>Datei hier hereinziehen oder</Typography>
      <Box sx={{ m: 1, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button
          variant="contained"
          color="secondary"
          disabled={uploading}
          onClick={() => document.querySelector("input[type='file']").click()}
          startIcon={uploadSuccess ? <Icon iconName="checkCircle" /> : <Icon iconName="upload" />}
        >
          {uploadSuccess ? 'Upload erfolgreich!' : 'Datei auswählen'}
        </Button>
        {uploading && (
          <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CircularProgress color="secondary" />
            <Typography variant="body2" mt={2} color="text.secondary" align="center" sx={{ mt: 2 }}>
              Deine Datei wird gerade hochgeladen. Dies kann einige Momente dauern, bitte hab etwas Geduld.
            </Typography>
          </Box>
        )}
        {uploadError && (
          <Typography variant="body2" color="error" align="center" sx={{ mt: 2 }}>
            {uploadError}
          </Typography>
        )}
      </Box>
      <Typography variant="body2" mt={2} color="text.secondary" align="center">
        Du kannst nur PDFs hochladen. Maximal 50 MB pro Datei.
      </Typography>
    </Box>
  );
};

export default UploadFilesField;
