import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, CircularProgress } from '@mui/material';
import EditFileButton from '../Button/EditFileButton';
import DeleteFileButton from '../Button/DeleteFileButton';
import DownloadFileButton from '../Button/DownloadFileButton';
import Icon from '../../components/Icon';
import { useTheme } from '@mui/material/styles';

// 1) Helper to see if all statuses are completed
const isFileFullyProcessed = (file) =>
  file?.text_status === 'completed' &&
  file?.images_status === 'completed' &&
  file?.metadata_status === 'completed';

const FileList = ({
  files,
  filesError,
  isLoading,
  inProgressFileIds = [],  // array of file IDs being polled
  onFileClick,
  onFileUpdated,
  courseName,
  fileControlComponent,
  style,
  showEditAndDeleteButtons = true
}) => {
  const theme = useTheme();

  // 2) Track which files just finished (to show "ready" message for 3s)
  const [recentlyFinishedFileIds, setRecentlyFinishedFileIds] = useState([]);

  // 3) Each time `files` or `inProgressFileIds` changes, see if any file 
  //    was in progress but is now fully processed => show "Datei ist bereit!"
  useEffect(() => {
    files.forEach((file) => {
      const isFinished = isFileFullyProcessed(file);
      const wasInProgress = inProgressFileIds.includes(file.id);

      if (isFinished && wasInProgress) {
        // Add ID to `recentlyFinishedFileIds` if not already
        setRecentlyFinishedFileIds((prev) =>
          prev.includes(file.id) ? prev : [...prev, file.id]
        );

        // Remove after 3 seconds
        setTimeout(() => {
          setRecentlyFinishedFileIds((prev) => prev.filter((id) => id !== file.id));
        }, 3000);
      }
    });
  }, [files, inProgressFileIds]);

  return (
    <Paper
      elevation={4}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: { md: '400px', lg: '500px', xl: '600px' },
        borderRadius: '20px',
        width: '100%',
        ...style
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textAlign: 'left',
          pl: 1,
          pb: 2,
          backgroundColor: 'background.paper',
          width: '100%'
        }}
      >
        {courseName} - Meine Dateien
      </Typography>

      <Box
        sx={{
          overflowY: 'auto',
          flexGrow: 1,
          '&::-webkit-scrollbar': {
            width: '0.5em'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.3)',
            borderRadius: '4px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'darkgrey',
            borderRadius: '4px'
          }
        }}
      >
        {isLoading ? (
          <CircularProgress color="secondary" style={{ margin: 'auto' }} />
        ) : filesError ? (
          <Typography
            sx={{
              mt: 2,
              textAlign: 'left',
              width: '100%',
              color: 'text.secondary',
              pl: 1,
              pb: 2
            }}
          >
            Error: {filesError}
          </Typography>
        ) : files.length > 0 ? (
          files.map((file) => {
            const isFinished = isFileFullyProcessed(file);
            const isInProgress = inProgressFileIds.includes(file.id);
            const showReadyMessage = recentlyFinishedFileIds.includes(file.id);

            return (
              <Box
                key={file.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 2,
                  p: 2,
                  width: '100%',
                  '&:hover': {
                    backgroundColor: 'grey.200',
                    cursor: 'pointer'
                  }
                }}
                onClick={() => onFileClick(file)}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    maxWidth: 'calc(100% - 48px)',
                    overflow: 'hidden'
                  }}
                >
                  {fileControlComponent ? (
                    fileControlComponent(file)
                  ) : (
                    <Icon iconName="file" sx={{ color: theme.palette.primary.main }} />
                  )}

                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    {file.file_name}
                  </span>

                  {/* A) Show step-by-step statuses if not finished and isInProgress */}
                  {isInProgress && !isFinished && (
                    <>
                      {file.text_status !== 'completed' ? (
                        <Typography sx={{ ml: 2, color: 'grey.600' }}>
                          Texte werden verarbeitet...
                        </Typography>
                      ) : file.images_status !== 'completed' ? (
                        <Typography sx={{ ml: 2, color: 'grey.600' }}>
                          Bilder werden verarbeitet...
                        </Typography>
                      ) : file.metadata_status !== 'completed' ? (
                        <Typography sx={{ ml: 2, color: 'grey.600' }}>
                          Jetzt nur noch die Metadaten...
                        </Typography>
                      ) : null}
                    </>
                  )}

                  {/* B) If the file just finished, show "ready" message for 3s */}
                  {showReadyMessage && (
                    <Typography sx={{ ml: 2, color: 'green' }}>
                      Datei ist bereit!
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{ display: 'flex', gap: 1 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {showEditAndDeleteButtons && (
                    <>
                      <EditFileButton fileId={file.id} onFileUpdated={onFileUpdated} />
                      <DeleteFileButton fileId={file.id} onFileUpdated={onFileUpdated} />
                      <DownloadFileButton fileId={file.id} fileName={file.file_name} />
                    </>
                  )}
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography
            sx={{
              mt: 2,
              textAlign: 'left',
              width: '100%',
              color: 'text.secondary',
              pl: 1,
              pb: 2
            }}
          >
            Du hast noch keine Dateien hochgeladen. Bitte lade zunächst eine Datei hoch.
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default FileList;
