import React from 'react';
import { useState } from "react";
import { useAuth } from '../../contexts/AuthContext'; 
import "./LoginForm.css";
import { useNavigate } from 'react-router-dom';
import ENDPOINTS from '../../utils/apiConfig';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import {  ThemeProvider, CircularProgress, Alert, Snackbar } from '@mui/material';
import {useTheme} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { getTheme } from '../../styles/theme';
import Icon from '../../components/Icon';


const LoginForm = () => {
  const navigate = useNavigate();
  const theme = createTheme(getTheme('purple'));
  const { login } = useAuth();
  const [animate, setAnimate] = useState(false);
  const [loginInput, setLoginInput] = useState({
    value: "",
    isTouched: false,
  });
  const [password, setPassword] = useState({
    value: "",
    isTouched: false,
  });
  const [generalError, setGeneralError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getIsFormValid = () => {
    return loginInput.value.trim().length > 0 && password.value.length >= 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setGeneralError(""); // Reset errors
    setAnimate(false); // Reset animation

    if (getIsFormValid()) {
      const loginData = {
        login: loginInput.value,
        password: password.value,
      };

      try {
        const response = await fetch(ENDPOINTS.LOGIN, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(loginData),
        });

        const data = await response.json();
        if (response.ok) {
          login(data);
          localStorage.setItem('accessToken', data.access);
          localStorage.setItem('refreshToken', data.refresh);
          setIsLoginSuccessful(true); // Set login success
          setTimeout(() => {
            navigate('/main'); // Redirect after showing success message
          }, 1500);
        } else {
          if (data.error) {
            if (data.error.includes("Email not verified")) {
              setGeneralError("Bitte bestätige zunächst deine Email Adresse.");
            } else {
              setGeneralError("Entweder deine Login Daten oder dein Passwort ist falsch. Bitte überprüfe deine Eingaben.");
            }
          }
          setAnimate(true); // Trigger shake animation only on error
        }
      } catch (error) {
        console.error("Network error:", error);
        setGeneralError("Ein Netzwerkfehler ist aufgetreten. Bitte versuche es später erneut.");
        setAnimate(true); // Trigger shake animation on network error
      }
      setLoading(false);
    } else {
      setGeneralError("Bitte überprüfen deine Eingaben.");
      setAnimate(true); // Trigger shake animation if form is invalid
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Anmeldung
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              className={animate ? 'shake-animation' : ''}
              margin="normal"
              required
              fullWidth
              id="loginInput"
              label="Email oder Nutzername"
              name="loginInput"
              autoComplete="loginInput"
              autoFocus
              value={loginInput.value}
              onChange={(e) => setLoginInput({ ...loginInput, value: e.target.value })}
              onBlur={() => setLoginInput({ ...loginInput, isTouched: true })}
            />
            <TextField
              className={animate ? 'shake-animation' : ''}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Passwort"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              value={password.value}
              onChange={(e) => setPassword({ ...password, value: e.target.value })}
              onBlur={() => setPassword({ ...password, isTouched: true })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      sx={{
                        width: 25,
                        height: 25,
                        padding: 0,
                        marginRight: 0.5,
                      }}
                    >
                      <Icon iconName={showPassword ? 'unsee' : 'see'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {generalError && (
              <Typography color="error" sx={{ mt: 2 }}>
                {generalError}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading || !getIsFormValid()}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : isLoginSuccessful ? (
                "Anmeldung erfolgreich!"
              ) : (
                "Anmelden"
              )}
            </Button>
            <Grid container direction="column" spacing={1}>
              <Grid item xs>
                <Link href="reset-password" variant="body2">
                  Passwort vergessen?
                </Link>
              </Grid>
              <Grid item xs>
                <Link href="/register" variant="body2">
                  Noch kein Konto? Hier registrieren
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LoginForm;
