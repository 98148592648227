import React, { useState } from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import LearningMode from '../../features/LearningMode/LearningMode';

const StartLearningModeButton = ({ questionSetId, onOpenChange }) => {
  const [open, setOpen] = useState(localStorage.getItem(`learningModeOpen-${questionSetId}`) === 'true');

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleClickOpen = () => {
    setOpen(true);
    localStorage.setItem(`learningModeOpen-${questionSetId}`, 'true');
    onOpenChange?.(true);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem(`learningModeOpen-${questionSetId}`);
    onOpenChange?.(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={handleClickOpen}
        sx={{
          // Different heights, widths, and font sizes for large vs. smaller screens
          height: { xs: '48px', lg: '71px' },
          width: { xs: '140px', lg: '244px' },
          fontSize: { xs: '14px', lg: '16px' },
          fontWeight: 700,
          boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.25)',
          lineHeight: 1.5,
          display: 'flex',
          flexDirection: isLargeScreen ? 'column' : 'row', // e.g., stack on large screens, side-by-side on small
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {/* Conditionally render text based on screen size */}
        {isLargeScreen ? (
          <>
            <span>Lernmodus</span>
            <span>starten</span>
          </>
        ) : (
          "Lernmodus starten"
        )}
      </Button>

      <LearningMode open={open} onClose={handleClose} questionSetId={questionSetId} />
    </>
  );
};

export default StartLearningModeButton;
