import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Slider,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as SadSmiley } from '../../assets/SadSmiley.svg';
import { ReactComponent as NeutralSmiley } from '../../assets/NeutralSmiley.svg';
import { ReactComponent as HappySmiley } from '../../assets/HappySmiley.svg';
import { ReactComponent as UnknownSmiley } from '../../assets/UnknownSmiley.svg';
import Icon from '../../components/Icon'; 
import ShuffleButton from '../../components/Button/ShuffleButton'; 

const MenuContainer = styled(Paper)(({ theme, isPinned }) => ({
  width: '267px',
  height: isPinned ? '220px' : '58px',
  borderRadius: '15px',
  backgroundColor: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  position: 'absolute',
  bottom: 0,
  left: 0,
  transition: 'height 0.3s ease',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'stretch',
  // Only apply hover effect if not pinned
  '&:hover': isPinned
    ? {}
    : {
        height: '220px',
      },
}));

const CollapsedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '58px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ExpandedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '16px',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
}));

const QuizMenu = ({
  totalQuestions,
  onCheckboxChange,
  onSliderChange,
  sliderValue,
  checkboxState,
  pinnedStateKey,
  isShuffled,     
  toggleShuffle,
}) => {
  const theme = useTheme();
  const [isPinned, setIsPinned] = useState(() => {
    const savedPinnedState = localStorage.getItem(pinnedStateKey);
    return savedPinnedState ? JSON.parse(savedPinnedState) : true;
  });

  const handleCheckboxChange = (event) => {
    const updatedCheckboxState = {
      ...checkboxState,
      [event.target.name]: event.target.checked,
    };
    onCheckboxChange(updatedCheckboxState);
  };

  const handleSliderChange = (event, newValue) => {
    onSliderChange(newValue);
  };

  const togglePinned = () => {
    setIsPinned((prev) => {
      const newState = !prev;
      localStorage.setItem(pinnedStateKey, JSON.stringify(newState));
      return newState;
    });
  };

  if (sliderValue === null || totalQuestions === undefined) {
    return null; // Or a placeholder
  }

  return (
    <MenuContainer elevation={3} isPinned={isPinned}>
      <CollapsedContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center', 
            width: '100%',
            mb: 2,
            mt: 2,
          }}
        >
          {/* Smiley Icons */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SadSmiley
              style={{
                width: 22,
                height: 22,
                margin: '0 4px',
                color: theme.palette.primary.main,
              }}
            />
            <NeutralSmiley
              style={{
                width: 22,
                height: 22,
                margin: '0 4px',
                color: theme.palette.primary.main,
              }}
            />
            <HappySmiley
              style={{
                width: 22,
                height: 22,
                margin: '0 4px',
                color: theme.palette.primary.main,
              }}
            />
            <UnknownSmiley
              style={{
                width: 26,
                height: 26,
                margin: '0 4px',
                color: theme.palette.primary.main,
              }}
            />
          </Box>
        </Box>
        {/* Shuffle Button */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mr: 3.5
          }}
        >
          <ShuffleButton isShuffled={isShuffled} toggleShuffle={toggleShuffle} />
        </Box>
      </CollapsedContent>
      {/* Expanded Content */}
      <ExpandedContent>
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center', // Center vertically
            justifyContent: 'space-between', // Space between heading and pin
            mb: 1, // Bottom margin for spacing
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ color: 'black', fontWeight: 'normal' }}
          >
            Zeige mir...
          </Typography>
          {/* Pin Icon */}
          <IconButton
            onClick={togglePinned}
            sx={{
              color: isPinned ? theme.palette.primary.main : theme.palette.grey[500],
              height: 35,
              width: 35,
            }}
          >
            {/* Use your custom pin icon */}
            <Icon 
              iconName="pin" 
              sx={{
                fontSize: 30, 
              }} 
            />
          </IconButton>
        </Box>

        {/* Checkbox Container */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            mb: 1,
          }}
        >
          <FormControlLabel
            sx={{ flex: 1 }}
            control={
              <Checkbox
                size="small"
                name="sad"
                checked={checkboxState.sad}
                onChange={handleCheckboxChange}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            }
            label={
              <SadSmiley
                style={{
                  width: 18,
                  height: 18,
                  color: theme.palette.primary.main,
                }}
              />
            }
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{ flex: 1 }}
            control={
              <Checkbox
                size="small"
                name="okay"
                checked={checkboxState.okay}
                onChange={handleCheckboxChange}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            }
            label={
              <NeutralSmiley
                style={{
                  width: 18,
                  height: 18,
                  color: theme.palette.primary.main,
                }}
              />
            }
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{ flex: 1 }}
            control={
              <Checkbox
                size="small"
                name="happy"
                checked={checkboxState.happy}
                onChange={handleCheckboxChange}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            }
            label={
              <HappySmiley
                style={{
                  width: 18,
                  height: 18,
                  color: theme.palette.primary.main,
                }}
              />
            }
            labelPlacement="end"
          />
          <FormControlLabel
            sx={{ flex: 1 }}
            control={
              <Checkbox
                size="small"
                name="none"
                checked={checkboxState.none}
                onChange={handleCheckboxChange}
                sx={{
                  color: theme.palette.primary.main,
                  '&.Mui-checked': {
                    color: theme.palette.primary.main,
                  },
                }}
              />
            }
            label={
              <UnknownSmiley
                style={{
                  width: 20,
                  height: 20,
                  color: theme.palette.primary.main,
                }}
              />
            }
            labelPlacement="end"
          />
        </Box>
        {/* Slider Component */}
        <Box sx={{ width: '100%', mt: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Slider
              value={sliderValue}
              min={1}
              max={totalQuestions}
              onChange={handleSliderChange}
              valueLabelDisplay="on"
              componentsProps={{
                valueLabel: {
                  sx: {
                    backgroundColor: 'transparent',
                    color: theme.palette.text.primary,
                    fontSize: 12,
                    fontWeight: 'bold',
                    top: 45,
                    '&:before': {
                      display: 'none',
                    },
                  },
                },
              }}
              sx={{
                flex: 1,
              }}
            />
            <Typography variant="body2" sx={{ ml: 2, whiteSpace: 'nowrap' }}>
              von {totalQuestions}
            </Typography>
          </Box>
        </Box>
      </ExpandedContent>
    </MenuContainer>
  );
};

export default QuizMenu;
