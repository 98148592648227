import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '../../components/Icon';
import EditQuestionSetDialog from '../../features/EditQuestionSetDialog/EditQuestionSetDialog'; 
import {useTheme} from '@mui/material';

export default function EditQuestionSetButton({ questionSetId, onQuestionSetUpdated }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="edit"
                onClick={handleClickOpen}
                sx={{
                    width: 20,
                    height: 20,
                    padding: 0,
                    color: 'grey', // Default color
                    '&:hover': {
                        color: theme.palette.primary.main, 
                        backgroundColor: 'transparent',
                        '& svg': { // Targeting the icon itself inside the IconButton
                            animation: 'wiggle 0.2s ease-in-out infinite' // Applying the wiggle animation
                        }
                    },
                    '@keyframes wiggle': { // Defining the keyframes for the wiggle animation
                        '0%, 100%': { transform: 'rotate(-3deg)' },
                        '50%': { transform: 'rotate(3deg)' },
                        
                    }
                }}
            >
                <Icon iconName="pen" style={{ width: 20, height: 20 }} />
            </IconButton>
            {open && <EditQuestionSetDialog open={open} onClose={handleClose} questionSetId={questionSetId} onQuestionSetUpdated={onQuestionSetUpdated} />}
        </>
    );
}

