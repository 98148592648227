import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, IconButton, Box } from '@mui/material';
import Icon from '../../components/Icon';
import { fetchUserDetails, updateUserDetails } from '../../services/UserService'; // Adjust the path as needed
import SentimentSatisfiedAlt from '@mui/icons-material/SentimentSatisfiedAlt';
import EditableField from './EditableField';

const labelMap = {
  username: "Nutzername",
  email: "Emailadresse",
  first_name: "Vorname",
  last_name: "Nachname",
  gender: "Geschlecht",
  birthday: "Geburtstag",
  university: "Universität",
  degree: "Studiengang",
  major: "Hauptfach"
};

const ProfileMenu = ({ open, handleClose }) => {
  const [userDetails, setUserDetails] = useState({
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    gender: "",
    birthday: "",
    university: "",
    degree: "",
    major: ""
  });

  useEffect(() => {
    if (open) {
      fetchUserDetails().then(data => {
        setUserDetails(data);
      }).catch(error => {
        console.error('Error fetching user details:', error);
      });
    }
  }, [open]);

  const handleSaveField = (key, value) => {
    const updatedDetails = { ...userDetails, [key]: value };
    updateUserDetails({ [key]: value }).then(() => {
      setUserDetails(updatedDetails);
    }).catch(error => {
      console.error('Failed to update field:', error);
    });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" PaperProps={{
      sx: {
        borderRadius: '20px',
        padding: "10px"
      }
    }}>
      <DialogTitle sx={{ m: 0, p: 3, display: 'flex', alignItems: 'center', position: 'relative' }}>
      <SentimentSatisfiedAlt sx={{ mr: 1, color: 'purple'}} /> 
      Mein Profil
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            height: 40,
            width: 40,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon iconName="cross" style={{ fontSize: '50px' }}  />
        </IconButton>
      </DialogTitle>
      <Box sx={{ p: 3 }}>
        {Object.entries(userDetails).map(([key, value]) => (
          <EditableField
            key={key}
            label={labelMap[key]}
            value={value}
            editable={!['username', 'email'].includes(key)} // Make username and email non-editable
            onSave={(newValue) => handleSaveField(key, newValue)}
          />
        ))}
      </Box>
    </Dialog>
  );
};

export default ProfileMenu;






