// File: usePollFiles.js
import { useEffect, useRef } from 'react';
import { fetchFileDetails } from '../services/FileService';

const usePollFiles = (fileIds, updateFileInState, setFileError, setCurrentFileIds) => {
  // Store file IDs that finished in the last poll
  const toRemoveNextTime = useRef([]);

  useEffect(() => {
    if (!fileIds || fileIds.length === 0) return;

    const pollAllFiles = async () => {
      try {
        const newlyCompleted = [];
        const failedFileIds = [];

        for (const fileId of fileIds) {
          try {
            // 1) Fetch details for this file ID
            const fileData = await fetchFileDetails(fileId);

            // 2) Update the file in your global/local state
            updateFileInState(fileData);

            // 3) Check if all statuses are completed
            const allDone =
              fileData.text_status === 'completed' &&
              fileData.images_status === 'completed' &&
              fileData.metadata_status === 'completed';

            // 4) If done, mark it for next-round removal
            if (allDone) {
              newlyCompleted.push(fileId);
            }
          } catch (err) {
            // If ANY error for this file, log and remove from polling
            console.error(`Error fetching file details (ID=${fileId}):`, err);
            failedFileIds.push(fileId);
            // Optional: setFileError(`Failed to fetch file ID=${fileId}: ${err.message}`);
          }
        }

        // 5) Remove IDs flagged from the *previous* poll
        if (toRemoveNextTime.current.length > 0) {
          setCurrentFileIds((prevIds) =>
            prevIds.filter((id) => !toRemoveNextTime.current.includes(id))
          );
          toRemoveNextTime.current = [];
        }

        // 6) Mark newly completed IDs to remove *next* time
        if (newlyCompleted.length > 0) {
          toRemoveNextTime.current = newlyCompleted;
        }

        // 7) Remove any failed file IDs immediately
        if (failedFileIds.length > 0) {
          setCurrentFileIds((prevIds) => prevIds.filter((id) => !failedFileIds.includes(id)));
        }
      } catch (error) {
        // This is a fallback if something outside the loop fails
        console.error('Error polling file details:', error);
        setFileError((prev) => prev || 'One or more files failed to fetch.');
      }
    };

    const intervalId = setInterval(pollAllFiles, 5000);
    return () => clearInterval(intervalId);
  }, [fileIds, updateFileInState, setFileError, setCurrentFileIds]);

  return null;
};

export default usePollFiles;
