import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ENDPOINTS from '../../utils/apiConfig';
import { Box } from '@mui/material';

export default function DeleteQuestionSetDialog({ questionSetId, open, onClose, onQuestionSetUpdated }) {
    const [questionSetName, setQuestionSetName] = useState('');  // State to store the question set name

    useEffect(() => {
        if (open) {
            const fetchQuestionSetDetails = async () => {
                const accessToken = localStorage.getItem('accessToken');
                try {
                    const response = await fetch(ENDPOINTS.GET_QUESTIONSET(questionSetId), {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });
                    if (!response.ok) {
                        throw new Error('Failed to fetch question set details');
                    }
                    const data = await response.json();
                    console.log('Fetched question set details:', data); // Log fetched data
                    setQuestionSetName(data.name);  // Set the fetched question set name to state
                } catch (error) {
                    console.error('Error fetching question set details:', error);
                }
            };
            fetchQuestionSetDetails();
        }
    }, [open, questionSetId]);

    const handleDelete = async () => {
        const accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(ENDPOINTS.DELETE_QUESTIONSET(questionSetId), {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to delete the question set');
            }
            onClose();  // Close dialog on successful delete
            onQuestionSetUpdated();  // Trigger question set list refresh
        } catch (error) {
            console.error('Error deleting question set:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} PaperProps={{
            sx: {
              borderRadius: '20px' 
            }
          }}>
            <DialogTitle>Frageset löschen</DialogTitle>
            <DialogContent>
                <Typography variant="body1">
                    Achtung! Wenn du das Frageset "{questionSetName}" löschst, kannst du es nicht mehr für die Erstellung von Lernmaterialien nutzen. Bist du dir sicher?
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'space-between', padding: '0 24px 16px 24px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={handleDelete} color="error">
                        Löschen
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} color="primary">
                        Abbrechen
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
}
