import React, { useState } from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import AnsweringMode from '../../features/AnsweringMode/AnsweringMode';

const StartAnsweringModeButton = ({ questionSetId, onOpenChange }) => {
  const [open, setOpen] = useState(localStorage.getItem(`answeringModeOpen-${questionSetId}`) === 'true');

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleClickOpen = () => {
    setOpen(true);
    localStorage.setItem(`answeringModeOpen-${questionSetId}`, 'true');
    onOpenChange?.(true);
  };

  const handleClose = () => {
    setOpen(false);
    localStorage.removeItem(`answeringModeOpen-${questionSetId}`);
    onOpenChange?.(false);
  };

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        onClick={handleClickOpen}
        sx={{
          height: { xs: '48px', lg: '71px' },
          width: { xs: '140px', lg: '244px' },
          fontSize: { xs: '14px', lg: '16px' },
          fontWeight: 700,
          boxShadow: '0px 4px 4px 2px rgba(0, 0, 0, 0.25)',
          lineHeight: 1.5,
          display: 'flex',
          flexDirection: isLargeScreen ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isLargeScreen ? (
          <>
            <span>Antwortmodus</span>
            <span>starten</span>
          </>
        ) : (
          "Antwortmodus starten"
        )}
      </Button>

      <AnsweringMode open={open} onClose={handleClose} questionSetId={questionSetId} />
    </>
  );
};

export default StartAnsweringModeButton;
