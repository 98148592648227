import React from 'react';
import { styled, Box, List, Divider, Drawer as MuiDrawer } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ThinkiLogo from "../assets/ThinkiLogo.svg";
import Icon from '../components/Icon'; 
import { MainListItems, CourseListItems, SecondaryListItems, CreateCourseListItem } from './ListItemsNavbar';
import {useTheme} from '@mui/material';

const drawerWidth = 240;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ open }) => ({
  '& .MuiDrawer-paper': {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1)',  // Standard transition for width
    boxSizing: 'border-box',
    backgroundColor: 'transparent', // Keeping the transparency
    border: 'none',
    ...(!open && {
      overflowX: 'hidden',
      transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1)',  // Repeating transition for consistency
      width: 72,  // Assuming 72px is the collapsed width
    }),
  },
}));



const SideDrawer = ({ open, toggleDrawer, onCourseCreated, onPanicClick }) => {
  const theme = useTheme(); 
  return (
    <Drawer variant="permanent" open={open}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: 0,
        mt: 3,
        ml: 2.2,
        gap: 0.4, // Adds space between the logo and the toggle button
      }}>
        <IconButton
          style={{ color: theme.palette.icon.main }}
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{ marginRight: 2 , height: 30, width: 30, padding: 0, ml:1 }}
        >
          <Icon iconName="sidebar" style={{ width: 30, height: 30 }}/>
        </IconButton>
        <img src={ThinkiLogo} alt="Thinki Logo" style={{ height: 40, width: 'auto' }} />
      </Box>
      <List component="nav">
        <MainListItems />
        <Divider sx={{ my: 0.5, borderColor: 'rgba(0, 0, 0, 0.10)', width: "90%", mx: 'auto'}} />
        <CourseListItems onCourseCreated={onCourseCreated} />
        <CreateCourseListItem onCourseCreated={onCourseCreated} />
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <List component="nav">

      <SecondaryListItems onPanicClick={onPanicClick} />
      </List>
    </Drawer>
  );
};

export default SideDrawer;
