import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  Slider,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import Icon from '../../components/Icon';
import ShuffleButton from '../../components/Button/ShuffleButton';

/**
 * TODO: 
 * 1) Make sure you have these custom icons: zeroCircle, oneCircle, twoCircle, etc.
 * 2) "questionCircle" for unrated questions
 */

const MenuContainer = styled(Paper)(({ theme, isPinned }) => ({
  width: '267px',
  height: isPinned ? '250px' : '58px',
  borderRadius: '15px',
  backgroundColor: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  position: 'absolute',
  bottom: 0,
  left: 0,
  transition: 'height 0.3s ease',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'stretch',
  // Expand on hover if not pinned
  '&:hover': isPinned
    ? {}
    : {
        height: '250px',
      },
}));

const CollapsedContent = styled(Box)(() => ({
  width: '100%',
  height: '58px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px', // small spacing
}));

const ExpandedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '16px',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
}));

const AnsweringMenu = ({
  pinnedStateKey,
  totalQuestions,
  sliderValue,
  onSliderChange,
  checkboxState,
  onCheckboxChange,
  isShuffled,
  toggleShuffle,
}) => {
  const theme = useTheme();

  // Pin logic
  const [isPinned, setIsPinned] = useState(() => {
    const savedPinnedState = localStorage.getItem(pinnedStateKey);
    return savedPinnedState ? JSON.parse(savedPinnedState) : true;
  });

  const togglePinned = () => {
    setIsPinned((prev) => {
      const newState = !prev;
      localStorage.setItem(pinnedStateKey, JSON.stringify(newState));
      return newState;
    });
  };

  // If no questions to show or slider not initialized, return null (like QuizMenu)
  if (sliderValue == null || totalQuestions === undefined) {
    return null;
  }

  // local handlers for checkbox & slider
  const handleCheckboxChange = (event) => {
    const updatedCheckboxState = {
      ...checkboxState,
      [event.target.name]: event.target.checked,
    };
    onCheckboxChange(updatedCheckboxState);
  };

  const handleSliderChange = (event, newValue) => {
    onSliderChange(newValue);
  };


  return (
    <MenuContainer elevation={3} isPinned={isPinned}>
      {/* Collapsed Content */}
      <CollapsedContent>
      <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      mb: 1.3,
    }}
  >
        <Icon
          iconName="questionCircle"
          style={{
            width: 22,
            height: 22,
            color: theme.palette.primary.main,
          }}
        />

        {/* Shuffle Button */}
        <ShuffleButton isShuffled={isShuffled} toggleShuffle={toggleShuffle} />
      </Box>
      </CollapsedContent>

      {/* Expanded Content */}
      <ExpandedContent>
        {/* Heading & Pin */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1,
          }}
        >
          <Typography variant="subtitle1" sx={{ color: 'black', fontWeight: 'normal' }}>
            Zeige mir...
          </Typography>
          <IconButton
            onClick={togglePinned}
            sx={{
              color: isPinned ? theme.palette.primary.main : theme.palette.grey[500],
              height: 35,
              width: 35,
            }}
          >
            <Icon iconName="pin" />
          </IconButton>
         
        </Box>

        {/* Checkboxes for rating 0–5 & unrated */}
<Box
  sx={{
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)', // 4 columns in the first row
    gap: 0.5, // Adjust spacing between checkboxes
    mb: 1, // Margin below the entire group
  }}
>
  <FormControlLabel
    control={
      <Checkbox
        size="small"
        name="0"
        checked={checkboxState["0"]}
        onChange={handleCheckboxChange}
        sx={{
          color: theme.palette.primary.main,
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        }}
      />
    }
    label={
      <Icon
        iconName="zeroCircle"
        style={{ width: 18, height: 18, color: theme.palette.primary.main }}
      />
    }
    labelPlacement="end"
  />
  <FormControlLabel
    control={
      <Checkbox
        size="small"
        name="1"
        checked={checkboxState["1"]}
        onChange={handleCheckboxChange}
        sx={{
          color: theme.palette.primary.main,
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        }}
      />
    }
    label={
      <Icon
        iconName="oneCircle"
        style={{ width: 18, height: 18, color: theme.palette.primary.main }}
      />
    }
    labelPlacement="end"
  />
  <FormControlLabel
    control={
      <Checkbox
        size="small"
        name="2"
        checked={checkboxState["2"]}
        onChange={handleCheckboxChange}
        sx={{
          color: theme.palette.primary.main,
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        }}
      />
    }
    label={
      <Icon
        iconName="twoCircle"
        style={{ width: 18, height: 18, color: theme.palette.primary.main }}
      />
    }
    labelPlacement="end"
  />
  <FormControlLabel
    control={
      <Checkbox
        size="small"
        name="3"
        checked={checkboxState["3"]}
        onChange={handleCheckboxChange}
        sx={{
          color: theme.palette.primary.main,
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        }}
      />
    }
    label={
      <Icon
        iconName="threeCircle"
        style={{ width: 18, height: 18, color: theme.palette.primary.main }}
      />
    }
    labelPlacement="end"
  />
  <FormControlLabel
    control={
      <Checkbox
        size="small"
        name="4"
        checked={checkboxState["4"]}
        onChange={handleCheckboxChange}
        sx={{
          color: theme.palette.primary.main,
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        }}
      />
    }
    label={
      <Icon
        iconName="fourCircle"
        style={{ width: 18, height: 18, color: theme.palette.primary.main }}
      />
    }
    labelPlacement="end"
    sx={{ gridColumn: 'span 1' }}
  />
  <FormControlLabel
    control={
      <Checkbox
        size="small"
        name="5"
        checked={checkboxState["5"]}
        onChange={handleCheckboxChange}
        sx={{
          color: theme.palette.primary.main,
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        }}
      />
    }
    label={
      <Icon
        iconName="fiveCircle"
        style={{ width: 18, height: 18, color: theme.palette.primary.main }}
      />
    }
    labelPlacement="end"
    sx={{ gridColumn: 'span 1' }}
  />
  <FormControlLabel
    control={
      <Checkbox
        size="small"
        name="unrated"
        checked={checkboxState.unrated}
        onChange={handleCheckboxChange}
        sx={{
          color: theme.palette.primary.main,
          '&.Mui-checked': {
            color: theme.palette.primary.main,
          },
        }}
      />
    }
    label={
      <Icon
        iconName="questionCircle"
        style={{ width: 18, height: 18, color: theme.palette.primary.main }}
      />
    }
    labelPlacement="end"
    sx={{ gridColumn: 'span 1' }}
  />
</Box>

        {/* Slider for the number of cards */}
        <Box sx={{ width: '100%', mt: 1, mb: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Slider
              value={sliderValue}
              min={1}
              max={Math.max(1, totalQuestions)}
              onChange={handleSliderChange}
              valueLabelDisplay="on"
              componentsProps={{
                valueLabel: {
                  sx: {
                    backgroundColor: 'transparent',
                    color: theme.palette.text.primary,
                    fontSize: 12,
                    fontWeight: 'bold',
                    top: 45,
                    '&:before': {
                      display: 'none',
                    },
                  },
                },
              }}
              sx={{ flex: 1 }}
            />
            <Typography variant="body2" sx={{ ml: 2, whiteSpace: 'nowrap' }}>
              von {totalQuestions}
            </Typography>
          </Box>
        </Box>
      </ExpandedContent>
    </MenuContainer>
  );
};

export default AnsweringMenu;
