// src/components/Button/DownloadFileButton.jsx
import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '../../components/Icon';
import ENDPOINTS from '../../utils/apiConfig';
import { useTheme } from '@mui/material';

export default function DownloadFileButton({ fileId, fileName }) {
    const theme = useTheme();
  const handleDownload = async (e) => {
    e.stopPropagation(); // Prevent parent onClick

    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(ENDPOINTS.DOWNLOAD(fileId), {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to download file');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = fileName || 'downloaded-file'; // Use fileName if available
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <IconButton
      aria-label="download"
      onClick={handleDownload}
      sx={{
        width: 20,
        height: 20,
        padding: 0,
        color: 'grey',
        '&:hover': {
          color: theme.palette.primary.main,
          backgroundColor: 'transparent',
          '& svg': {
            animation: 'drop 0.5s ease-in-out', 
            animationIterationCount: 'infinite',
            animationDirection: 'alternate'
          }
        },
        '@keyframes drop': {
          '0%': { transform: 'translateY(0)' },
          '50%': { transform: 'translateY(4px)' },
          '100%': { transform: 'translateY(0)' }
        }
      }}
    >
      <Icon iconName="download" style={{ fontSize: '20px' }} />
    </IconButton>
  );
}
