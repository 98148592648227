import React from 'react';
import { Box, Typography, CircularProgress, Paper } from '@mui/material';
import Icon from '../Icon';              
import EditCourseButton from '../Button/EditCourseButton';       
import DeleteCourseButton from '../Button/DeleteCourseButton';  

export default function CourseList({
  courses = [],
  isLoading = false,
  onCourseSelect,
  onCourseUpdated,
  title = 'Meine Kurse',
}) {
  return (
    <Paper
      elevation={4}
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        height: '600px',
        width: '100%',
        borderRadius: '20px',
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textAlign: 'left',
          pl: 1,
          pb: 2,
          backgroundColor: 'background.paper',
          width: '100%',
        }}
      >
        {title}
      </Typography>

      {/* Loading or Course List */}
      {isLoading ? (
        <CircularProgress
          color="secondary"
          style={{ margin: 'auto' }}
        />
      ) : courses.length > 0 ? (
        courses.map((course) => (
          <Box
            key={course.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              p: 2,
              width: '100%',
              '&:hover': {
                backgroundColor: 'grey.200',
                cursor: 'pointer',
              },
            }}
            onClick={() => onCourseSelect?.(course.id)}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                overflow: 'hidden',
              }}
            >
              <Icon
                iconName="folder"
                style={{ width: '35px', height: '35px' }}
              />
              <span
                style={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {course.name}
              </span>
            </Box>
            {/* Edit/Delete buttons */}
            <Box
              sx={{ display: 'flex', gap: 1 }}
              onClick={(e) => e.stopPropagation()} // so we don't trigger onCourseSelect
            >
              <EditCourseButton
                courseId={course.id}
                onCourseUpdated={onCourseUpdated}
              />
              <DeleteCourseButton
                courseId={course.id}
                onCourseUpdated={onCourseUpdated}
              />
            </Box>
          </Box>
        ))
      ) : (
        <Typography
          sx={{
            mt: 2,
            textAlign: 'left',
            width: '100%',
            color: 'text.secondary',
            pl: 1,
            pb: 2,
          }}
        >
          Du hast noch keine Kurse erstellt. Beginne damit,
          einen neuen Kurs zu erstellen.
        </Typography>
      )}
    </Paper>
  );
}
