import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ENDPOINTS from '../../utils/apiConfig';

export default function EditCourseDialog({ courseId, open, onClose, onCourseUpdated }) {
  const [courseName, setCourseName] = useState('');

  // Function to fetch current course details
  const fetchCourseDetails = async () => {
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${ENDPOINTS.COURSES}/${courseId}`, {
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch course details');
      }
      const data = await response.json();
      setCourseName(data.name);  // Set the fetched name as initial state
    } catch (error) {
      console.error('Error fetching course details:', error);
    }
  };

  // Effect to fetch course details when the dialog opens
  useEffect(() => {
    if (open) {
      fetchCourseDetails();
    }
  }, [open, courseId]);  // Depend on open and courseId to refetch when they change

  const handleSave = async (event) => {
    event.preventDefault();
    const accessToken = localStorage.getItem('accessToken');
    try {
      const response = await fetch(`${ENDPOINTS.COURSES}/${courseId}`, {
        method: 'PATCH',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: courseName })
      });
      if (!response.ok) {
        throw new Error('Failed to update course name');
      }
      onClose();  // Close dialog on successful update
      onCourseUpdated();  // Trigger course list refresh on MainPage
    } catch (error) {
      console.error('Error updating course name:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{
      sx: {
        borderRadius: '20px' 
      }
    }}>
      <DialogTitle>Kursnamen bearbeiten</DialogTitle>
      <form onSubmit={handleSave}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Kursname"
            type="text"
            fullWidth
            variant="outlined"
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Abbrechen
          </Button>
          <Button type="submit" color="primary">
            Speichern
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
