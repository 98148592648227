import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Paper,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';

import { ReactComponent as SadSmiley } from '../../assets/SadSmiley.svg';
import { ReactComponent as NeutralSmiley } from '../../assets/NeutralSmiley.svg';
import { ReactComponent as HappySmiley } from '../../assets/HappySmiley.svg';
import { ReactComponent as UnknownSmiley } from '../../assets/UnknownSmiley.svg';
import Icon from '../../components/Icon';

const MenuContainer = styled(Paper)(({ theme, isPinned }) => ({
  width: '267px',
  height: isPinned ? '230px' : '58px',
  borderRadius: '15px',
  backgroundColor: '#FFF',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  position: 'absolute',
  bottom: 0,
  right: 0,
  transition: 'height 0.3s ease',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column-reverse',
  alignItems: 'center',
  '&:hover': isPinned
    ? {}
    : {
        height: '230px',
      },
}));

const CollapsedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '58px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ExpandedContent = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '16px',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
}));

const StatisticsMenu = ({ questions, pinnedStateKey }) => {
  const theme = useTheme();

  const [isPinned, setIsPinned] = useState(() => {
    const savedPinnedState = localStorage.getItem(pinnedStateKey);
    return savedPinnedState ? JSON.parse(savedPinnedState) : true;
  });

  const togglePinned = () => {
    setIsPinned((prev) => {
      const newState = !prev;
      localStorage.setItem(pinnedStateKey, JSON.stringify(newState));
      return newState;
    });
  };

  // Count how many questions in each status
  const [statusCounts, setStatusCounts] = useState({
    sad: 0,
    okay: 0,
    happy: 0,
    none: 0,
  });

  useEffect(() => {
    const counts = questions.reduce((acc, question) => {
      const status = question.flashcard_status || 'none';
      acc[status] = (acc[status] || 0) + 1;
      return acc;
    }, { sad: 0, okay: 0, happy: 0, none: 0 });

    setStatusCounts(counts);
  }, [questions]);

  // Track the random rotation for each line in each category
  const [rotations, setRotations] = useState({
    sad: [],
    okay: [],
    happy: [],
    none: [],
  });

  const prevStatusCounts = useRef(statusCounts);

  // On component mount, initialize the rotations array
  useEffect(() => {
    setRotations({
      sad: Array.from({ length: statusCounts.sad }, () => (Math.random() - 0.5) * 10),
      okay: Array.from({ length: statusCounts.okay }, () => (Math.random() - 0.5) * 10),
      happy: Array.from({ length: statusCounts.happy }, () => (Math.random() - 0.5) * 10),
      none: Array.from({ length: statusCounts.none }, () => (Math.random() - 0.5) * 10),
    });
    prevStatusCounts.current = statusCounts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // run once on mount

  // Every time statusCounts changes, we either add or remove lines
  useEffect(() => {
    ['sad', 'okay', 'happy', 'none'].forEach((status) => {
      const prevCount = prevStatusCounts.current[status] || 0;
      const currentCount = statusCounts[status] || 0;

      if (currentCount > prevCount) {
        // Add new lines
        const numToAdd = currentCount - prevCount;
        setRotations((prevRot) => ({
          ...prevRot,
          [status]: [
            ...prevRot[status],
            ...Array.from({ length: numToAdd }, () => (Math.random() - 0.5) * 10),
          ],
        }));
      } else if (currentCount < prevCount) {
        // Remove some lines
        setRotations((prevRot) => ({
          ...prevRot,
          [status]: prevRot[status].slice(0, currentCount),
        }));
      }
    });

    prevStatusCounts.current = statusCounts;
  }, [statusCounts]);

  /**
   * Render a stack of lines for a category, max of 100 lines.
   */
  const renderStack = (rotationsArray) => {
    const maxStackHeight = 80; // The maximum vertical space for the stack
    const lineSpacing = 2;
    const maxLines = 38; // Hard cap on number of lines

    // If we have more lines than maxLines, just slice them
    const displayedRotations = rotationsArray.slice(0, maxLines);
    let count = displayedRotations.length;

    let lineHeight = 1.5;
    let neededHeight = count * (lineHeight + lineSpacing);

    // If neededHeight is bigger than the max, shrink lineHeight so it fits
    if (neededHeight > maxStackHeight) {
      lineHeight = (maxStackHeight - count * lineSpacing) / count;
      // Ensure lineHeight doesn't go below 1px so lines remain visible
      if (lineHeight < 1) {
        lineHeight = 1;
      }
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-end',
          height: `${maxStackHeight}px`,
          overflow: 'hidden',
          marginBottom: '4px', // slightly less than your old 5px
        }}
      >
        {displayedRotations.map((rotation, index) => (
          <Box
            key={index}
            sx={{
              width: '20px',
              height: `${lineHeight}px`,
              backgroundColor: theme.palette.primary.main,
              marginBottom: `${lineSpacing}px`,
              transform: `rotate(${rotation}deg)`,
            }}
          />
        ))}
      </Box>
    );
  };

  return (
    <MenuContainer elevation={3} isPinned={isPinned}>
      {/* Collapsed Content with custom statistic icon */}
      <CollapsedContent>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mb: 2,
          }}
        >
          <Icon
            iconName="statistic"
            style={{ color: theme.palette.primary.main }}
            sx={{
              width: 22,
              height: 22,
            }}
          />
        </Box>
      </CollapsedContent>

      {/* Expanded Content */}
      <ExpandedContent>
        {/* Pin Icon at Top-Right Corner */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: 1,
          }}
        >
          <IconButton
            onClick={togglePinned}
            sx={{
              color: isPinned ? theme.palette.primary.main : theme.palette.grey[500],
              height: 35,
              width: 35,
            }}
            aria-label={isPinned ? 'Unpin menu' : 'Pin menu'}
          >
            <Icon iconName="pin" sx={{ fontSize: 30 }} />
          </IconButton>
        </Box>

        {/* Four categories: sad, neutral, happy, none */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexGrow: 1,
          }}
        >
          {/* Sad */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStack(rotations.sad)}
            <SadSmiley
              style={{
                width: 22,
                height: 22,
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              variant="body2"
              sx={{
                mt: 0.2, // smaller top margin
                fontSize: '0.75rem', // smaller font
                color: theme.palette.grey[500], // light grey color
              }}
            >
              {statusCounts.sad}
            </Typography>
          </Box>

          {/* Neutral */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStack(rotations.okay)}
            <NeutralSmiley
              style={{
                width: 22,
                height: 22,
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              variant="body2"
              sx={{
                mt: 0.2,
                fontSize: '0.75rem',
                color: theme.palette.grey[500],
              }}
            >
              {statusCounts.okay}
            </Typography>
          </Box>

          {/* Happy */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStack(rotations.happy)}
            <HappySmiley
              style={{
                width: 22,
                height: 22,
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              variant="body2"
              sx={{
                mt: 0.2,
                fontSize: '0.75rem',
                color: theme.palette.grey[500],
              }}
            >
              {statusCounts.happy}
            </Typography>
          </Box>

          {/* None (unrated) */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {renderStack(rotations.none)}
            <UnknownSmiley
              style={{
                width: 22,
                height: 22,
                color: theme.palette.primary.main,
              }}
            />
            <Typography
              variant="body2"
              sx={{
                mt: 0.2,
                fontSize: '0.75rem',
                color: theme.palette.grey[500],
              }}
            >
              {statusCounts.none}
            </Typography>
          </Box>
        </Box>
      </ExpandedContent>
    </MenuContainer>
  );
};

export default StatisticsMenu;


