import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import ENDPOINTS from '../../utils/apiConfig';
import Icon from '../../components/Icon'; // To show the check icon

const callToActionMessages = [
  "Deine Meinung zählt! Sag uns, was du denkst.",
  "Hilf uns Thinki noch besser zu machen!",
  "Keine Scheu, raus mit deiner Meinung!",
  "Wir sind ganz Ohr. Was möchtest du mitteilen?",
  "Schenk uns dein Feedback und wir wachsen gemeinsam!",
];

const FeedbackDialog = ({ open, onClose, onFeedbackSubmitted }) => {
  const [feedbackText, setFeedbackText] = useState('');
  const location = useLocation();
  const [ctaMessage, setCtaMessage] = useState('');
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const page = location.pathname;
  const queryParams = new URLSearchParams(location.search);
  const tab = queryParams.get('tab');

  useEffect(() => {
    if (open) {
      // Pick a random CTA message whenever the dialog opens
      const randomMsg = callToActionMessages[Math.floor(Math.random() * callToActionMessages.length)];
      setCtaMessage(randomMsg);
      setFeedbackSubmitted(false);
      setFeedbackText('');
    }
  }, [open]);

  const handleSend = async () => {
    if (feedbackText.trim() === '') {
      // Could show a short local message or just do nothing
      // For simplicity, let's do a small alert:
      alert('Bitte gib dein Feedback ein.');
      return;
    }

    const feedbackData = {
      feedback_text: feedbackText,
      page: page,
      ...(tab && { tab: tab }),
    };

    try {
      const accessToken = localStorage.getItem('access_token');

      const response = await fetch(ENDPOINTS.FEEDBACK, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify(feedbackData),
      });

      if (response.ok) {
        // Feedback submitted successfully
        setFeedbackSubmitted(true);
        setFeedbackText('');

        
        // Optional: auto-close after a delay
        setTimeout(() => {
          onClose();
        }, 3000);
      } else {
        const errorData = await response.json();
        console.error('Error submitting feedback:', errorData);
        alert('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.');
      }
    } catch (error) {
      console.error('Network error:', error);
      alert('Ein Netzwerkfehler ist aufgetreten. Bitte versuche es später erneut.');
    }
  };

  const handleCancel = () => {
    setFeedbackText('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="sm" fullWidth PaperProps={{
      sx: {
        borderRadius: '20px' 
      }
    }}>
      <DialogTitle>Wir freuen uns über dein Feedback</DialogTitle>
      <DialogContent>
        {/* Show a fun, random call-to-action message */}
        <Box sx={{ mb: 2, fontSize: '0.9em', color: 'gray' }}>
          {ctaMessage}
        </Box>

        <TextField
          autoFocus
          margin="dense"
          label="Dein Feedback"
          type="text"
          fullWidth
          multiline
          rows={4}
          variant="outlined"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          disabled={feedbackSubmitted} // Disable if feedback is already submitted
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="secondary" sx={{ marginRight: 'auto' }} disabled={feedbackSubmitted}>
          Abbrechen
        </Button>
        
        {!feedbackSubmitted ? (
          <Button onClick={handleSend} color="primary" variant="contained">
            Senden
          </Button>
        ) : (
          // After submission: show a check icon and "Danke!"
          <Button  variant="contained" startIcon={<Icon iconName="check" />} sx={{ animation: 'pulse 1s infinite alternate' }}>
            Danke!
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
