import React from 'react';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material';
import Icon from '../../components/Icon';
import DeleteCourseDialog from '../../features/DeleteCourseDialog/DeleteCourseDialog';

export default function EditCourseButton({ courseId, onCourseUpdated }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="edit"
                onClick={handleClickOpen}
                sx={{
                    width: 20, 
                    height: 20,
                    padding: 0,
                    color: 'grey',
                    '&:hover': {
                        color: theme.palette.primary.main,
                        backgroundColor: 'transparent',
                        // Target the svg element directly:
                        '& svg': {
            animation: 'bounce 0.5s infinite alternate' // A slight bounce animation
                }
                    },
                    '@keyframes bounce': {
                '0%': { transform: 'translateY(0)' },
                '100%': { transform: 'translateY(-3px)' }
                }
                }}
            >
                <Icon iconName="trash" style={{ width: 20, height: 20 }} />
            </IconButton>
            {open && (
                <DeleteCourseDialog 
                    open={open} 
                    onClose={handleClose} 
                    courseId={courseId} 
                    onCourseUpdated={onCourseUpdated} 
                />
            )}
        </>
    );
}
