import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CssBaseline,
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from '@mui/material';
import { useTheme } from '@mui/material';
import SideDrawer from '../../components/Drawer';
import { fetchUserCourses } from '../../services/CourseService';
import { fetchUserDetails } from '../../services/UserService';
import CreateCourseButton from '../../components/Button/CreateCourseButton';
import EditCourseButton from '../../components/Button/EditCourseButton';
import DeleteCourseButton from '../../components/Button/DeleteCourseButton';
import { useSidebar } from '../../contexts/SidebarContext';
import Icon from '../../components/Icon';
import CourseList from '../../components/Lists/CourseList';

const drawerWidth = 240;

// Keep these intervals outside the component scope or as component-level variables
let hueInterval = null;
let twistInterval = null;
let currentHue = 0;

export default function MainPage() {
  // -----------------------------
  // 1) All Hooks at top level
  // -----------------------------
  const { isOpen, toggleDrawer } = useSidebar();
  const navigate = useNavigate();
  const theme = useTheme();

  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userName, setUserName] = useState('');

  // Panic-related states
  const [panicMode, setPanicMode] = useState(false);
  const [showPostPanicMessage, setShowPostPanicMessage] = useState(false);
  const [postPanicMessage, setPostPanicMessage] = useState('');
  const [messageVisible, setMessageVisible] = useState(false);

  // The top-level gradient from MUI theme
  const originalGradient = theme.palette.gradient.background;

  // ** Ref for the background Box (instead of body) **
  const backgroundRef = useRef(null);

  // A small list of messages for post-panic
  const postPanicMessages = [
    'Atme durch und leg wieder los!',
    'Puh, kurze Pause und weiter gehts.',
    'Alles gut, weiter geht\'s!',
    'Tief durchatmen, du schaffst das!',
    'Kurzer Reset - jetzt mit frischer Energie!',
  ];

  // -----------------------------
  // 2) Load data on mount
  // -----------------------------
  useEffect(() => {
    async function fetchData() {
      try {
        const userDetails = await fetchUserDetails();
        setUserName(userDetails.username);

        const fetchedCourses = await fetchUserCourses();
        setCourses(fetchedCourses);

      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  // -----------------------------
  // 3) Set --panic-bg on mount
  // -----------------------------
  useEffect(() => {
    // If originalGradient is blank, you may want a fallback
    document.documentElement.style.setProperty('--panic-bg', originalGradient);
  }, [originalGradient]);

  // -----------------------------
  // 4) Handlers
  // -----------------------------
  const handleCourseClick = (courseId) => {
    navigate(`/course/${courseId}`);
  };

  const handleCourseCreated = async () => {
    setIsLoading(true);
    try {
      const fetchedCourses = await fetchUserCourses();
      setCourses(fetchedCourses);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  // -----------------------------
  // 5) Panic Mode Logic
  // -----------------------------
  const handlePanicClick = () => {
    setPanicMode(true);
    startPanicEffects();

    setTimeout(() => {
      stopPanicEffects();
      setPanicMode(false);
    }, 4000);
  };

  const startPanicEffects = () => {
    // Shake entire page with a CSS class
    document.body.classList.add('panic-shake');
    // Start color cycling on backgroundRef
    startColorCycle();
    // Twisting
    startRandomTwists();
  };

  const stopPanicEffects = () => {
    document.body.classList.remove('panic-shake');
    stopColorCycle();
    stopRandomTwists();

    // Restore the original gradient
    document.documentElement.style.setProperty('--panic-bg', originalGradient);

    // Show random post-panic message
    const randomMsg = postPanicMessages[Math.floor(Math.random() * postPanicMessages.length)];
    setPostPanicMessage(randomMsg);
    setShowPostPanicMessage(true);

    setTimeout(() => {
      setMessageVisible(true);
    }, 50);

    setTimeout(() => {
      setMessageVisible(false);
    }, 3000);

    setTimeout(() => {
      setShowPostPanicMessage(false);
    }, 4000);
  };

  // -----------------------------
  // 5.a) Hue-Rotation (Color Cycle)
  // -----------------------------
  const startColorCycle = () => {
    currentHue = 0;
    if (backgroundRef.current) {
      backgroundRef.current.style.filter = 'hue-rotate(0deg)';
    }
    hueInterval = setInterval(() => {
      currentHue = (currentHue + 20) % 360;
      if (backgroundRef.current) {
        backgroundRef.current.style.filter = `hue-rotate(${currentHue}deg)`;
      }
    }, 50);
  };

  const stopColorCycle = () => {
    if (hueInterval) {
      clearInterval(hueInterval);
      hueInterval = null;
    }
    if (backgroundRef.current) {
      backgroundRef.current.style.filter = '';
    }
  };

  // -----------------------------
  // 5.b) Random Twists
  // -----------------------------
  const startRandomTwists = () => {
    const selectors = ['.paper-content', 'button', 'p', 'h1', 'h2', 'h3'];
    twistInterval = setInterval(() => {
      selectors.forEach((sel) => {
        const elements = document.querySelectorAll(sel);
        elements.forEach((elem) => {
          if (Math.random() < 0.5) {
            const rotate = Math.floor(Math.random() * 40) - 20;
            const scale = 1 + (Math.random() * 0.5 - 0.25);
            elem.style.transition = 'transform 0.2s ease';
            elem.style.transform = `rotate(${rotate}deg) scale(${scale})`;
          } else {
            // Reset
            elem.style.transform = '';
          }
        });
      });
    }, 300);
  };

  const stopRandomTwists = () => {
    if (twistInterval) {
      clearInterval(twistInterval);
      twistInterval = null;
    }
    const allElements = document.querySelectorAll('.paper-content, button, p, h1, h2, h3');
    allElements.forEach((elem) => {
      elem.style.transform = '';
      elem.style.transition = '';
    });
  };

  // -----------------------------
  // 6) Render the page
  // -----------------------------
  return (
    <>
      <Box
        // *** Attach the ref here ***
        ref={backgroundRef}
        sx={{
          background: panicMode
            ? 'var(--panic-bg)'
            : theme.palette.gradient.background,
          minHeight: '100vh',
          width: '100%',
          overscrollBehavior: 'contain',
        }}
      >
        <CssBaseline />

        {/* The side drawer with your panic button */}
        <SideDrawer
          open={isOpen}
          toggleDrawer={toggleDrawer}
          onCourseCreated={handleCourseCreated}
          onPanicClick={handlePanicClick}
        />

        {/* The main content container */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 1,
            width: isOpen
              ? `calc(100% - ${drawerWidth}px)`
              : `calc(100% - 72px)`,
            ml: isOpen ? `${drawerWidth}px` : `72px`,
            transform: isOpen ? 'none' : 'translateX(-5px)',
            transition: `
              width 500ms cubic-bezier(0.2, 1, 0.2, 1),
              margin 500ms cubic-bezier(0.2, 1, 0.2, 1)
            `,
            backgroundColor: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            justifyContent: 'space-between',
            ...(isOpen && {
              animation: 'openBounce 500ms cubic-bezier(0.2, 1, 0.2, 1) forwards',
            }),
            '@keyframes openBounce': {
              '0%': { transform: 'translateX(-5px)' },
              '70%': { transform: 'translateX(3px)' },
              '100%': { transform: 'translateX(0)' },
            },
          }}
        >
          <Paper
            elevation={3}
            sx={{
              flexGrow: 1,
              borderRadius: '10px',
              backgroundColor: 'rgba(255, 255, 255, 0.97)',
              boxShadow: '0px 0px 15px 0px rgba(0, 0, 0, 0.20)',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Container
              maxWidth="false"
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              {/* Top area (e.g., create course button) */}
              <Box
                alignItems="center"
                display="flex"
                sx={{ flexGrow: 1, paddingTop: 8 }}
              >
                <CreateCourseButton
                  onCourseCreated={handleCourseCreated}
                  color="primary"
                />
              </Box>

              {/* Course list */}
              <Grid container spacing={3} sx={{ mt: 2, mb: 2 }}>
                <Grid item xs={12}>
                <CourseList
                courses={courses}
                isLoading={isLoading}
                onCourseSelect={handleCourseClick}
                onCourseUpdated={handleCourseCreated}
                title="Meine Kurse" // optional
                 />
                </Grid>
              </Grid>
            </Container>
          </Paper>
        </Box>
        {/* The post-panic message overlay */}
        {showPostPanicMessage && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              backgroundColor: 'rgba(0,0,0,0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 9999,
              color: '#fff',
              fontSize: '4em',
              fontFamily: 'Nunito, sans-serif',
              opacity: messageVisible ? 1 : 0,
              transition: 'opacity 1s ease-in-out',
              textAlign: 'center',
              p: 3,
            }}
          >
            {postPanicMessage}
          </Box>
        )}
      </Box>
    </>
  );
}
