import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '../../components/Icon';
import DeleteQuestionDialog from '../../features/DeleteQuestionDialog/DeleteQuestionDialog';
import {useTheme} from '@mui/material';

export default function DeleteQuestionButton({ questionId, onQuestionDeleted }) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <IconButton
                aria-label="delete"
                onClick={handleClickOpen}
                sx={{
                    width: 15, 
                    height: 15,
                    padding: 0,
                    color: 'grey',
                    '&:hover': {
                        color: theme.palette.primary.main, 
                        backgroundColor: 'transparent',
                        '& svg': {
            animation: 'bounce 0.5s infinite alternate' // A slight bounce animation
                }
                    },
                    '@keyframes bounce': {
                '0%': { transform: 'translateY(0)' },
                '100%': { transform: 'translateY(-3px)' }
                }
                }}
            >
                <Icon iconName="trash" style={{ fontSize: '15px', color: "grey" }} />
            </IconButton>
            {open && <DeleteQuestionDialog open={open} onClose={handleClose} questionId={questionId} onQuestionDeleted={onQuestionDeleted} />}
        </>
    );
}
