import React from 'react';
import IconButton from '@mui/material/IconButton';
import Icon from '../../components/Icon';
import {useTheme} from '@mui/material';

export default function EditQuestionButton({ onClick }) {
    const theme = useTheme();
    return (
        <IconButton
            aria-label="edit"
            onClick={onClick}
            sx={{
                width: 15, 
                height: 15,
                padding: 0,
                color: 'grey',
                '&:hover': {
                        color: theme.palette.primary.main, 
                        backgroundColor: 'transparent',
                        '& svg': { // Targeting the icon itself inside the IconButton
                            animation: 'wiggle 0.2s ease-in-out infinite' // Applying the wiggle animation
                        }
                    },
                    '@keyframes wiggle': { // Defining the keyframes for the wiggle animation
                        '0%, 100%': { transform: 'rotate(-3deg)' },
                        '50%': { transform: 'rotate(3deg)' },
                        
                    }
            }}
        >
            <Icon iconName="pen" style={{ width: 20, height: 20 }} />
        </IconButton>
    );
}
